.Hero{
    display: flex;
    justify-content: space-between;
}

.left-h{
    padding: 2rem;
    padding-top: 1.5rem;    
    flex: 3;

}

.right-h{
    flex: 1;
    position: relative;
    background-color: orange;
}

.the-best-ad{
    border: 1px solid black;
    position: relative;
    width: fit-content;
    background-color: #373C41;
    text-transform : uppercase;
    border-radius: 4rem;
    color: white;
    align-items: center;
    display: flex;
    justify-content: flex-start;
    margin-top: 4rem;
    padding: 20px 13px;

}

.the-best-ad > span{
    z-index: 2;
}

.the-best-ad > div{
    position: absolute;
    background-color: orange;
    width: 5.4rem;
    height: 80%;
    left:8px;
    border-radius: 3rem;
    z-index: 1;
}

.hero-text{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    text-transform: uppercase;
    font-size: 4.5rem;
    font-weight: bold;
    color: white;
    text-overflow: inherit;
}

.hero-text > div:nth-of-type(3){
    font-size: 1rem;
    font-weight: 200;
    text-transform: none;
    letter-spacing: 1px;
    width: 80%;
}



.figures{
    display: flex;
    gap: 2rem;
}

.figures > div{
    display: flex;
    flex-direction: column;
}

.figures > div > span:nth-of-type(1){
    color: white;
    font-size: 2rem;
}


.figures > div > span:nth-of-type(2){
    color: grey;
    text-transform: uppercase;
}

.hero-buttons{
    display: flex;
    gap:1rem;
    font-weight: normal;
}

.hero-buttons >:nth-child(1){ 
    color: white;
    background-color: orange;
    width: 8rem;
}
.hero-buttons >:nth-child(2){ 
    color: white;
    background-color: transparent;
    width: 8rem;
    border: 2px solid orange;
}

.right-h > btn{
    position: absolute;
    right: 3rem;
    top: 2rem;
    color: black;
}

.heart-rate{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: #454D55;
    width: fit-content;
    padding: 1rem;
    align-items: center;
    position: absolute;
    right : 4rem;
    top:7rem;
}

.heart-rate>img{
    width: 2rem;
}

.heart-rate>:nth-child(2){
    color: grey;
}


.heart-rate>:nth-child(3){
    color: white;
    font-size: 1.5rem;
}

.hero-image{
    position: absolute;
    top: 10rem;
    right: 8rem;
    width: 23rem;
}

.hero-image-back{
    position: absolute;
    top:4rem;
    right: 20rem;
    z-index: -1;
    width: 15rem;
}

.calories{
    display: flex;
    gap: 2rem;
    border-radius: 5px;
    background-color: #525252;
    padding: 1rem;
    position: absolute;
    top:28rem;
    right: 34rem;
    width: max-content;
}

.calories > img{
    width: 3rem;
}

.calories > div{
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}

.calories > div >:nth-child(1){
    color: grey;
}

.calories > div >:nth-child(2){
    color: white;
    font-size: 1.5rem;
}

.hero-blur{
    width:22rem;
    height: 30rem;
    left: 0;
}

 @media screen  and (max-width: 768px){
    .Hero{
        flex-direction: column;
    }

    .hero-blur{
        width: 14rem;
    }

    .the-best-ad{
        margin-top: 0;
        font-size: small;
        align-self: center;
        transform: scale(0.8);


    }


    .hero-text{
        font-size: xx-large;
        align-items: center;
        justify-content: center;
        /* right: 199px; */


    }

    .hero-text >div:nth-of-type(3){
            font-size: small;
            font-weight: 200;
            letter-spacing: 1px;
            text-align: center;
    }

    .figures >div >span:nth-of-type(1){
        font-size: large;
    }

    .figures > div >span:nth-of-tyoe(2) {
        font-size: small;
    }

    .right-h{
        position:relative;
        background: none
    }


    .heart-rate{
        left:1rem;
        top:2rem;
    }

    .calories>img{
        width: 2rem;
    }

    .calories > div >:nth-child(2){
        color: white;
        font-size: 1rem;
    }

    .hero-image{
        position: relative;
        width : 15rem;
        align-self: center;

        left: 7rem;
        top: 4rem;
    }

    .hero-image-back{
        width: 15rem;
        left: 2rem;
        top: 8rem;
    }
}